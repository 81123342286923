import {getCurrentWeek} from '../../helper/weekOfTheMonthHelper'
import { Card, CardBody } from "reactstrap";
import topic1 from "../../assets/img/media/topic-1.jpg";
import topic2 from "../../assets/img/media/topic-2.jpg";
import topic3 from "../../assets/img/media/topic-3.jpg";
import topic4 from "../../assets/img/media/topic-4.jpg";
import topic5 from "../../assets/img/media/topic-5.jpg";

interface Challenges  {
    image: string;  
    name: string;  
    description: string;
}

export default function WeeklyChallengeCard() {

    const challenges : Challenges[] = [
        {
          image : topic1,
          name : "Coffee connect with a colleague.",
          description : "Will you include a different colleague in a chat? Who can you learn something new about today?"
        },
        {
          image : topic2,
          name : "1 minute ME",
          description : "Will you take a minute each day for yourself?  Will you spend 1 minute of your day for you?"
        },
        {
          image : topic3,
          name : "Push for prevention!",
          description : "Will you try some push ups (on the wall, table, or floor) each day? Will you try to do one more each day?"
        },
        {
          image : topic4,
          name : "Massive muscle minute!",
          description : "No, we aren't asking you to be Arnie!  Will you do as many squats as you can in a minute each day?"
        },
        {
          image : topic5,
          name : "Give gratitude",
          description : "Are there team members that would benefit from hearing your gratitude?  Who will you give gratitude to today?"
        }
    ];

    const currentWeek = getCurrentWeek(new Date()) || 1;
    const currentChallenge = challenges[currentWeek - 1];

    return (
        <Card className="h-100">
          <CardBody>
            <h3 style={{ marginBottom: "1rem" }}>Challenge</h3>
            <div className="d-flex flex-column justify-content-center items-center mt-2">
              <img
                style={{
                  width: "100%",
                  borderRadius: "0.375rem",
                  marginBottom: "1rem",
                }}
                src={currentChallenge.image}
                alt={currentChallenge.name}
              />
            </div>
            <h4 style={{ marginBottom: "1rem" }}>{currentChallenge.name}</h4>
            <p>{currentChallenge.description}</p>
          </CardBody>
        </Card>
      );
}
