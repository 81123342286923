import moment from 'moment';
// Define a type for the week data
interface Week {
  start: string;
  end: string;
}

interface LabeledWeek extends Week {
  weekNumber: number;
  month: string;
}

// Function to get the full weeks for a month based on Monday-Sunday, spanning across months
const getWeeksForMonth = (year: number, month: number): Week[] => {
    // Get the first and last day of the month
    const firstDayOfMonth = moment(`${year}-${month}-01`, 'YYYY-MM-DD');
    const lastDayOfMonth = firstDayOfMonth.clone().endOf('month');

    // Get the Monday before or on the first day of the month
    const startOfWeek = firstDayOfMonth.clone().isoWeekday(1);
    // Get the Sunday after or on the last day of the month
    const endOfWeek = lastDayOfMonth.clone().isoWeekday(7);
    // Create an array to hold each week (each week starts on Monday)
    const weeks: Week[] = [];

    let currentStartOfWeek = startOfWeek;

    // Loop through each week, starting on Monday, until the end of the week
    while (currentStartOfWeek.isBefore(endOfWeek)) {
        // Each week starts on Monday (currentStartOfWeek) and ends on Sunday
        const currentEndOfWeek = currentStartOfWeek.clone().endOf('isoWeek');

        weeks.push({
        start: currentStartOfWeek.format('YYYY-MM-DD'),
        end: currentEndOfWeek.format('YYYY-MM-DD'),
        });

        // Move to the next week
        currentStartOfWeek = currentStartOfWeek.add(1, 'week');
    }

    return weeks;
};

// Function to assign week numbers for each month dynamically
const assignWeeksToMonths = (weeks: Week[]): LabeledWeek[] => {
    let currentMonth = '';
    let weekCounter = 1;
    const labeledWeeks: LabeledWeek[] = [];
    weeks.forEach((week) => {

        const startDate = moment(week.start);
        const endDate = moment(week.end);

        let daysInStart = 0;
        let daysInEnd = 0;
        let weekMonth = ''

        for (let day = startDate.clone(); day.isSameOrBefore(endDate); day.add(1, 'day')) {
        if (day.month() === startDate.month()) {
            daysInStart++;
        } else if (day.month() === endDate.month()) {
            daysInEnd++;
        }
        }

        // Determine which month should claim the week based on the higher number of days
        if (daysInStart >= daysInEnd) {
            weekMonth = startDate.format('MMMM');
        } else {
            weekMonth = endDate.format('MMMM');
        }

        // Check if we moved to a new month (based on the end date of the week)
        if (weekMonth !== currentMonth) {
            currentMonth = moment(week.end).format('MMMM'); // Update current month to the new one
            weekCounter = 1; // Reset week counter for the new month
        }

        // Assign the week number for the corresponding month
        labeledWeeks.push({
            weekNumber: weekCounter,
            month: currentMonth,
            start: week.start,
            end: week.end,
        });

        // Increment the week counter
        weekCounter++;
    });
    return labeledWeeks;
};

// Function to get the current week number in the month based on the current date
export const getCurrentWeek = (date: Date): number => {
    const momentDate = moment(date);
    const year = momentDate.year();
    const month = momentDate.month() + 1; // Moment months are zero-based, so add 1

    // Get the labeled weeks for the month
    const weeks = assignWeeksToMonths(getWeeksForMonth(year, month));

    // Find which week the current date falls into
    for (const week of weeks) {
        if (momentDate.isBetween(moment(week.start), moment(week.end), 'day', '[]')) {
            return week.weekNumber; // Return the week number
        }
    }

    return null;
};

// Example Usage
// const currentWeek = getCurrentWeek(new Date());
// console.log(`We are in ${currentWeek}`);
