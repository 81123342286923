import {getCurrentWeek} from '../../helper/weekOfTheMonthHelper'
import { Card, CardBody } from "reactstrap";
import ChallengeImg from "../../assets/img/media/challenge.jpg";

interface Topics  {
    image: string;  
    name: string;
    description : string;
}

export default function WeeklyChallengeCard() {

    const topics : Topics[] = [
        {
          image : ChallengeImg,
          name : "Safety is everyone's business.",
          description : "Social connections are important for building a healthy culture at work, creating empathy and support."
        },
        {
          image : ChallengeImg,
          name : "Mental Health Minute.",
          description : "60 seconds of movement, meditation, breathwork, gratitude practice etc help to foster an improved mood state and better wellbeing."
        },
        {
          image : ChallengeImg,
          name : "Prevention is better than cure.",
          description : "1% Improvements each day accumulates to compound interest on your health."
        },
        {
          image : ChallengeImg,
          name : "Your body counts too!",
          description : "Using large muscle mass regularly helps to prevent injury, regulate blood sugar levels and improve mood."
        },
        {
          image : ChallengeImg,
          name : "Teamwork makes the dream work.",
          description : "Knowing you are seen and authentically appreciated is key to feeling included."
        },
    ];

    const currentWeek = getCurrentWeek(new Date()) || 1;
    const currentTopic = topics[currentWeek - 1];

    return (
        <Card className="h-100">
          <CardBody>
            <h3 style={{ marginBottom: "1rem" }}>Topic</h3>
            <div className="d-flex flex-column justify-content-center items-center mt-2">
              <img
                style={{
                  width: "100%",
                  borderRadius: "0.375rem",
                  marginBottom: "1rem",
                }}
                src={currentTopic.image}
                alt={currentTopic.name}
              />
            </div>
            <h4 style={{ marginBottom: "1rem" }}>{currentTopic.name}</h4>
            <p>{currentTopic.description}</p>
          </CardBody>
        </Card>
      );
}
